<template>
  <div class="outer-page">
    <div class="center-width">
      <div class="content">
        <div class="member">
          <ul>
            <li
              @click="goThere(item.id)"
              v-for="(item, index) in list"
              :key="index"
            >
              <a>
                <div class="m-img">
                  <img :src="item.servicePath + item.cover" alt="" />
                </div>
                <div class="m-info">
                  <h6>{{ item.brief }}</h6>
                  <p></p>
                </div>
                <div class="clears"></div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      list: [],
    };
  },
  computed: {
    //获取菜单
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    //获取当前的router
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  methods: {
    getList() {
      if (Object.keys(this.getSubMenu).length == 0) return;
      let menu = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (menu.Interface) {
        this.$post(menu.Interface, {
          pageSize: 999,
          pageNo: 1,
        }).then((res) => {
          this.list = res.list;
        });
      }
    },
    goThere(id) {
      this.$router.push({
        name: "mnewsInfo",
        query: {
          id: id,
          key: this.$route.name,
        },
      });
    },
  },
  mounted() {
    this.getList();
  },
  watch: {
    $route() {
      this.getList();
    },
    getSubMenu: {
      deep: true,
      handler() {
        this.getList();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  padding: 0.5rem 0;
  font-size: 0.577rem;
  line-height: 1rem;
  .center-width {
    width: 94%;
    margin: 0 3%;
    .content {
      font-size: 0.577rem;
      line-height: 0.88rem;
      color: #666666;
      //   padding-top: 0.5rem;
      .member {
        ul {
          list-style: none;
          li {
            height: 5.266rem;
            background: #efefef;
            margin-bottom: 0.5rem;
            a {
              color: #333;
              .m-img {
                float: left;
                width: 7rem;
                height: 5.266rem;
                overflow: hidden;
                img {
                  display: block;
                  height: 5.266rem;
                  width: 100%;
                }
              }
              .m-info {
                float: right;
                width: 49%;
                height: 5.266rem;
                background: #efefef;
                margin-right: 1%;
                h6 {
                  padding-top: 0.4rem;
                  background: url(../../../../public/imgs/icon/m-jt01.png) right
                    center no-repeat;
                  margin-bottom: 0.2rem;
                  font-size: 0.588rem;
                  color: #333333;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
                p {
                  line-height: 0.8rem;
                  height: 3.2rem;
                  overflow: hidden;
                  font-size: 0.488rem;
                  color: #787878;
                }
              }
              .clears {
                clear: both;
                line-height: 0;
                overflow: hidden;
                font-size: 0;
                height: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>